import * as React from 'react';
import { useCallback, useState } from 'react';
import { BaseTextFieldProps, Box, Divider, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Options as LayoutOptions, JoinFormat } from '../../models/core';
import { Accordion, AccordionDetails, AccordionSummary } from './Accordion';


interface MultiSwitchProps<T> {
    options: { [k: string]: NonNullable<T> };
    value: T;
    disabled?: boolean;
    onChange?: (value: T) => void;
}

function MultiSwitch<T>({ value, options, disabled, onChange }: MultiSwitchProps<T>): JSX.Element {
    const callOnChange = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>, value: T) => {
        onChange && onChange(value);
    }, [onChange]);
    return (
        <ToggleButtonGroup exclusive disabled={disabled} value={value} onChange={callOnChange} color='primary'>
            {Object.entries(options).map(([k, v], index) => (
                <ToggleButton key={`item-${index}`} value={v} sx={{ height: '24px' }}>{k}</ToggleButton>
            ))}
        </ToggleButtonGroup>
    )
}

const OptionRow = ({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element => (
    <Stack direction='row' justifyContent='space-between' alignItems='flex-end'>
        {children}
    </Stack>
)

interface TextOptionRowProps<T> extends BaseTextFieldProps  {
    option: T;
    editValue: T[];
    value: string;
    disabled?: boolean;
    options?: { [k: string]: NonNullable<T> };
    onChange?: (text: string, option: T) => void;
}

function TextOptionRow<T>({ options, value, option, editValue, disabled, onChange, ...props }: TextOptionRowProps<T>): JSX.Element {
    const [text, setText] = useState(value);

    const onSwitchCnage = useCallback((value: T) => {
        onChange && onChange(text, value);
    }, [onChange, text]);

    const onTextChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    }, []);

    const onTextBlur = useCallback(() => {
        onChange && onChange(text, option);
    }, [onChange, option, text]);

    React.useEffect(() => {
        setText(value);
    }, [value]);

    return <OptionRow>
        <TextField
            {...props}
            variant='standard'
            disabled={options && !editValue.includes(option) && !disabled}
            hiddenLabel
            name={props.placeholder?.toLowerCase().replaceAll(' ','_')}
            value={text}
            onChange={onTextChange}
            onBlur={onTextBlur}
        // sx={{
        //     '.Mui-disabled:before': {
        //         borderBottom: 'none'
        //     },
        //     '.MuiInput-root:before': {
        //         borderBottom: 'none'
        //     },
        // }}
        />
        {options
            ? <MultiSwitch<T> options={options} value={option} disabled={disabled} onChange={onSwitchCnage} />
            : <></>
        }
    </OptionRow>
}

interface Props {
    value: LayoutOptions;
    onChange: (value: LayoutOptions) => void;
}

const OptionsEditor = ({ value, onChange }: Props): JSX.Element => {
    const onIsMultiPlanChange = useCallback((isMultiPlan: boolean) => {
        onChange({
            ...value,
            isMultiPlan: isMultiPlan,
            ...(isMultiPlan ? {} : {
                planMeasuresFormat: 'no',
            })
        })
    }, [value, onChange]);

    const onPatientSheetChange = useCallback((text: string, option: boolean) => {
        onChange({
            ...value,
            patientsSheet: option ? (text || 'Patients') : undefined,
            ...(option ? {} : {
                patientMeasuresFormat: 'no',
            })
        });
    }, [value, onChange]);

    const onMeasuresSheetChange = useCallback((text: string, _option: boolean) => {
        onChange({ ...value, measuresSheet: text });
    }, [value, onChange]);

    const onClientMeasuresSheet = useCallback((text: string, option: boolean) => {
        onChange({ ...value, clientMeasuresSheet: option ? (text || 'Measures') : undefined });
    }, [value, onChange]);

    const onPlanMeasuresChange = useCallback((text: string, option: JoinFormat) => {
        onChange({
            ...value,
            planMeasuresSheet: option != 'no' ? (text || 'Plan Measures') : undefined,
            planMeasuresFormat: option
        });
    }, [value, onChange]);

    const onPatientMeasuresChange = useCallback((text: string, option: JoinFormat) => {
        onChange({
            ...value,
            patientMeasuresSheet: option == 'rows' ? (text || 'Patient Measures') : undefined,
            patientMeasuresFormat: option
        });
    }, [value, onChange]);
    const onClientChecksSheet = useCallback((text: string, option: boolean) => {
        onChange({ ...value, clientChecksSheet: option ? (text || 'Checks') : undefined });
    }, [value, onChange]);

    const onPlanChecksChange = useCallback((text: string, option: JoinFormat) => {
        onChange({
            ...value,
            planChecksSheet: option != 'no' ? (text || 'Plan Checks') : undefined,
            planChecksFormat: option
        });
    }, [value, onChange]);

    const onPatientChecksChange = useCallback((text: string, option: JoinFormat) => {
        onChange({
            ...value,
            patientChecksSheet: option == 'rows' ? (text || 'Patient Checks') : undefined,
            patientChecksFormat: option
        });
    }, [value, onChange]);

    const onProgramsSheet = useCallback((text: string, option: boolean) => {
        onChange({
            ...value,
            programsSheet: option ? (text || 'Programs') : undefined,
            checksSheet: option ? (value.checksSheet || 'Checks') : undefined
        });
    }, [value, onChange]);

    const onVisitsSheet = useCallback((text: string, option: boolean) => {
        onChange({
            ...value,
            visitsSheet: option ? (text || 'Visits') : undefined,
        });
    }, [value, onChange]);

    const onChecksSheet = useCallback((text: string) => {
        onChange({ ...value, checksSheet: text });
    }, [value, onChange]);

    const onCallAttemptsChange = useCallback((text: string) => {
        onChange({ ...value, numCallAttempts: Number.parseInt(text,10) });
    }, [value, onChange]);

    const onEmailAttemptsChange = useCallback((text: string) => {
        onChange({ ...value, numEmailAttempts: Number.parseInt(text,10) });
    }, [value, onChange]);

    const onSMSAttemptsChange = useCallback((text: string) => {
        onChange({ ...value, numSMSAttempts: Number.parseInt(text,10) });
    }, [value, onChange]);

    return (
        <>
            <Accordion>
                <AccordionSummary><b>Options</b></AccordionSummary>
                    <AccordionDetails>
                    <Divider/>
                    <OptionRow>
                        <Typography variant='subtitle1'>Multi-Plan</Typography>
                        <MultiSwitch
                            options={{ Yes: true, No: false }}
                            value={value.isMultiPlan}
                            onChange={onIsMultiPlanChange} />
                    </OptionRow>
                    <Stack direction='row' justifyContent='space-between' alignItems='flex-end'>
                        <Typography variant='subtitle1'>Call Attempts</Typography>
                        <TextOptionRow
                            value={value.numCallAttempts?.toString() || ''}
                            editValue={[true]}
                            option={!!value.visitsSheet}
                            placeholder='6'
                            title='Number of call attempts allowed per visit.'
                            disabled={value.visitsSheet == null}
                            type='number'
                            onChange={onCallAttemptsChange} />
                    </Stack>
                    <Stack direction='row' justifyContent='space-between' alignItems='flex-end'>
                        <Typography variant='subtitle1'>Email Attempts</Typography>
                        <TextOptionRow
                            value={value.numEmailAttempts?.toString() || ''}
                            editValue={[true]}
                            option={!!value.visitsSheet}
                            placeholder='1'
                            title='Number of email attempts allowed per visit.'
                            disabled={value.visitsSheet == null}
                            type='number'
                            onChange={onEmailAttemptsChange} />
                    </Stack>
                    <Stack direction='row' justifyContent='space-between' alignItems='flex-end'>
                        <Typography variant='subtitle1'>Text Attempts</Typography>
                        <TextOptionRow
                            value={value.numEmailAttempts?.toString() || ''}
                            editValue={[true]}
                            option={!!value.visitsSheet}
                            placeholder='1'
                            title='Number of text attempts allowed per visit.'
                            disabled={value.visitsSheet == null}
                            type='number'
                            onChange={onSMSAttemptsChange} />
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Divider/>
            <Accordion>
                <AccordionSummary><b>Import & Export</b></AccordionSummary>
                <AccordionDetails>
                    <Divider/>
                    <TextOptionRow
                        value={value.measuresSheet || ''}
                        // options={{ Yes: true, No: false }}
                        option={!!value.measuresSheet}
                        editValue={[true]}
                        placeholder='Measures'
                        title='Sheet with tracking of measures'
                        onChange={onMeasuresSheetChange} />
                    <TextOptionRow
                        value={value.checksSheet || ''}
                        // options={{ Yes: true, No: false }}
                        option={!!value.checksSheet}
                        editValue={[true]}
                        placeholder='Checks'
                        title='Sheet with tracking of checks; may be the same as measures'
                        disabled={value.programsSheet == null}
                        onChange={onChecksSheet} />
                    <Box height={16} width={1} />
                    <TextOptionRow
                        value={value.patientsSheet || ''}
                        options={{ Yes: true, No: false }}
                        option={!!value.patientsSheet}
                        editValue={[true]}
                        placeholder='Patients'
                        title='Roster of patients with demographic data and (if provided) available measures and checks'
                        onChange={onPatientSheetChange} />
                    <TextOptionRow
                        value={value.programsSheet || ''}
                        options={{ Yes: true, No: false }}
                        option={!!value.programsSheet}
                        editValue={[true]}
                        placeholder='Treatment Plans'
                        title='Sheet with treatment plans and check/frequency/period tuples'
                        onChange={onProgramsSheet} />
                    <TextOptionRow
                        value={value.visitsSheet || ''}
                        options={{ Yes: true, No: false }}
                        option={!!value.visitsSheet}
                        editValue={[true]}
                        placeholder='Visits'
                        title='Sheet with visits'
                        onChange={onVisitsSheet} />
                    <Box height={16} width={1} />
                    <TextOptionRow
                        value={value.clientMeasuresSheet || ''}
                        options={{ Yes: true, No: false }}
                        option={!!value.clientMeasuresSheet}
                        editValue={[true]}
                        placeholder='Client Measures'
                        title='Sheet with list of measures available for this client'
                        onChange={onClientMeasuresSheet} />
                    <TextOptionRow
                        value={value.planMeasuresSheet || ''}
                        options={{ Rows: 'rows', Columns: 'columns', No: 'no' }}
                        option={value.planMeasuresFormat}
                        editValue={['rows', 'columns']}
                        placeholder='Plan Measures'
                        title='Sheet specifying plans and measures available for every plan'
                        disabled={!value.isMultiPlan}
                        onChange={onPlanMeasuresChange} />
                    <TextOptionRow
                        value={value.patientMeasuresSheet || ''}
                        options={{ Rows: 'rows', Columns: 'columns', No: 'no' }}
                        option={value.patientMeasuresFormat}
                        editValue={['rows']}
                        placeholder='Patient Measures'
                        title={'ROWS: Sheet specifying measures available for every patient\nCOLUMNS: Measures are listed in columns of patient roster'}
                        disabled={value.patientsSheet == null}
                        onChange={onPatientMeasuresChange} />
                    <TextOptionRow
                        value={value.clientChecksSheet || ''}
                        options={{ Yes: true, No: false }}
                        option={!!value.clientChecksSheet}
                        editValue={[true]}
                        placeholder='Client Checks'
                        title='Sheet with list of checks available for this client'
                        onChange={onClientChecksSheet} />
                    <TextOptionRow
                        value={value.planChecksSheet || ''}
                        options={{ Rows: 'rows', Columns: 'columns', No: 'no' }}
                        option={value.planChecksFormat}
                        editValue={['rows', 'columns']}
                        placeholder='Plan Checks'
                        title='Sheet specifying plans and checks available for every plan'
                        disabled={!value.isMultiPlan}
                        onChange={onPlanChecksChange} />
                    <TextOptionRow
                        value={value.patientChecksSheet || ''}
                        options={{ Rows: 'rows', Columns: 'columns', No: 'no' }}
                        option={value.patientChecksFormat}
                        editValue={['rows']}
                        placeholder='Patient Checks'
                        title={'ROWS: Sheet specifying checks available for every patient\nCOLUMNS: Checks are listed in columns of patient roster'}
                        disabled={value.patientsSheet == null}
                        onChange={onPatientChecksChange} />
                    <Divider/>
                    </AccordionDetails>
            </Accordion>
        </>
    );
}

export default React.memo(OptionsEditor);