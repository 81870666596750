import * as React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import { IconButton, ListItemSecondaryAction, ListItemButton, Tooltip, useTheme } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import { Typography } from '@mui/material';
import { Patient, Visit } from '../models/core';
import { DateTime } from 'luxon';
import { analytics } from '../utils/analytics/zipAnalytics';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import { useLoading } from '../hooks/useLoading';

interface PatientListItemProps {
    patient: Patient;
    visit: Visit | null;
    isConflict?: boolean;
    onEdit?: (patient: Patient) => void;
    onOpenVisits?:(patient: Patient) => void;
    onAssign?: (patient: Patient) => void;
}

export const PatientListItem = ({ patient,visit, isConflict, onEdit, onOpenVisits, onAssign, ...props }: PatientListItemProps): JSX.Element => {
    const theme = useTheme();
    const navigate = useNavigate();
    const loading = useLoading();
    
    const onEditClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        analytics.breadcrumb("patient_edit_select", { patientId: patient._id});
        onEdit && onEdit(patient);
        event.stopPropagation();
        event.preventDefault();
    }, [onEdit, patient]);

    const onAssignClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        analytics.breadcrumb("patient_assign_select", { patientId: patient._id});
        onAssign && onAssign(patient);
        event.stopPropagation();
        event.preventDefault();
    }, [onAssign, patient]);

    return (
        <ListItem
            sx={{
                backgroundColor: isConflict
                    ? theme.palette.error.light + '40'
                    : undefined
            }}
            {...props}
            >
            <ListItemButton disableRipple>

                <ListItemText
                    primary={<Typography component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                        {patient.lastName}, {patient.firstName} ({
                            patient.dob && DateTime.fromISO(patient.dob).toLocaleString(DateTime.DATE_SHORT) || 'No DoB'})
                    </Typography>}
                    secondary={
                    <span>
                        Patient Status: {patient?.status || 'unknown'}
                        <br/>
                        Visit Status: {visit?.status || 'unknown'}
                        &nbsp; Contact Attempts: {visit?.numContacts || '0'}
                        <br/>
                        Last Contact Date: {visit?.lastContactDate || 'unknown'}
                        <br/>
                        Assigned To: {patient?.assignment?.assignedTo || 'none'}
                    </span>} />
                <ListItemSecondaryAction>
                    <Tooltip id="tt-edit-visit" title="Edit Visits">
                        <IconButton onClick={() => {
                                analytics.breadcrumb("patient_visit_select", { patientId: patient._id});
                                onOpenVisits && onOpenVisits(patient);
                            }}>
                            <MedicalServicesOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip id="tt-edit-assign" title="Assign"><IconButton onClick={onAssignClick}><AssignmentIndIcon/></IconButton></Tooltip>
                    <Tooltip id="tt-edit-patient" title="Edit Patient"><IconButton onClick={onEditClick}><PersonIcon /></IconButton></Tooltip>
                </ListItemSecondaryAction>
            </ListItemButton>
        </ListItem>
    );
};