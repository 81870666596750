import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogProps, Button, DialogActions, Snackbar, TextField, Box, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Agent, Provider, ScheduleItem, ScheduleJob, ScheduleJobResult } from "../../models/core"
import BookDialog from './BookDialog';
import ResultTable from './ResultTable';
import ResultGrid from './ResultGrid';
import AutoTabs, { AutoTab } from './AutoTabs';
import ResultMap from './ResultMap';
import { Wrapper } from '@googlemaps/react-wrapper';
import { day2date, slot2time } from '../../utils/slots';
import { DateTime } from 'luxon';
import { useAuth } from '../../hooks/useAuth';
import { API } from '../../utils/Api';


function nextWorkingDay(dt: DateTime) {
    dt = dt.plus({days: 1});
    if (dt.weekday >= 6) {
        dt = dt.plus({days: 8-dt.weekday})
    }
    return dt;
}

interface ResultDialogProps extends DialogProps {
    job: ScheduleJob;
    result: ScheduleJobResult;
    practiceId: number;
    open: boolean;
    jobId?: string;
    onClose?: () => void;
}

const ResultDialog = ({ job, result, practiceId, open, onClose, ...props }: ResultDialogProps): JSX.Element => {
    const auth = useAuth();
    const [snackOpen, setSnackOpen] = useState(false);
    const [startDate, setStartDate] = useState(
        DateTime.now().set({hour: 10, minute: 0, second: 0, millisecond: 0})
    );
    const [bookProps, setBookProps] = useState<{
        item: ScheduleItem; agent: Agent; provider: Provider; 
        date: DateTime; startHour: number
    } | null>(null);
    
    const agents = useMemo(() =>
        Object.fromEntries(result.agents?.map(agent => [agent.id, agent]) || []),
        [result]
    );    
    const providers = useMemo(() =>
        Object.fromEntries(result.providers?.map(agent => [agent.id, agent]) || []),
        [result]
    );
    const tableData = useMemo(() => {
        return result.schedule?.map(item => [
            agents[item.agentId].name,
            day2date(item.day, startDate),
            slot2time(item.startTime, startDate.hour),
            item.duration * 15 + 'min',
            item.address,
            item.patient.firstName + ' ' + item.patient.lastName,
            providers[item.providerId].name
        ]) || []
    }, [result, startDate, agents, providers]);
    const tableColumns = useMemo(() => ['Agent', 'Day', 'Time', 'Duration', 'Address', 'Patient Name', 'Provider'], []);
    const onExportClick = useCallback(async () => {
        navigator.clipboard.writeText(
            '"' + tableColumns.join('","') + '"\n' +
            tableData?.map(row => '"' + row.join('","') + '"').join('\n')
        );
        setSnackOpen(true);
    }, [tableColumns, tableData]);
    const onSaveScheduleClick = React.useCallback(async () => {
        return API.saveSchedulableSlots(result.schedule[0].patient.clientId,
            job?._id||'',startDate.startOf('day').plus({hour:8}).toISO({suppressMilliseconds: true, suppressSeconds: true})||'',
            auth?.user?.name||'');
    },[auth?.user?.name,result,startDate,job?._id]);

    const onStartDateChange = useCallback((value: DateTime | null) => {
        setStartDate(value || DateTime.now());
    }, []);

    const onSnackClose = useCallback((event: React.SyntheticEvent | Event, reason: string) => {
        if (reason != 'clickaway') {
            setSnackOpen(false);
        }
    }, []);
    
    const onBook = useCallback((agentId: string, day: number, startTime: number) => {
        const item = result.schedule.find(it =>
            it.agentId == agentId && it.day == day && it.startTime == startTime
        );
        console.log(item);
        if (item) {
            setBookProps({
                item,
                agent: agents[item.agentId],
                provider: providers[item.providerId],
                date: nextWorkingDay(startDate),
                startHour: startDate.hour
            });
        }
    }, [result, agents, providers, startDate]);

    const hideBookDialog = useCallback(() => setBookProps(null), []);

    useEffect(() => {
        if (!open) {
            setSnackOpen(false);
        }
    }, [open]);
    
    return <Dialog {...props} open={open} onClose={onClose} PaperProps={{ sx: { maxWidth: '1200px', width: '1200px' } }}>
        <DialogTitle>Schedule</DialogTitle>
        <DialogContent sx={{position: 'relative'}}>
            <AutoTabs sx={{height: '48px'}}>
                <AutoTab label='Table'>
                    <ResultTable data={tableData} header={tableColumns} groupColumns={2} />
                </AutoTab>
                <AutoTab label='Grid'>
                    <ResultGrid {...{result, practiceId, startDate, onBook}} />
                </AutoTab>
                <AutoTab label='Map'>
                    <Wrapper
                        id='google-maps'
                        apiKey='AIzaSyCyZ3l5G1h3MX75YMbxH0_fkt_G5fyKa8A'
                        libraries={['places']}
                    >
                        <ResultMap result={result} startDate={startDate} />
                    </Wrapper>
                </AutoTab>
            </AutoTabs>
        </DialogContent>
        <DialogActions>
            <Typography sx={{marginLeft: '16px', marginRight: '8px'}}>Start from</Typography>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DateTimePicker
                    value={startDate}
                    onChange={onStartDateChange} 
                    disableMaskedInput
                    renderInput={(params) => <TextField {...params} variant='standard' />}
                    InputProps={{sx: {width: '12em'}}}                    
                />
            </LocalizationProvider>
            <Box flexGrow={1} />
            <Button onClick={onSaveScheduleClick}>Save Schedule</Button>
            <Button onClick={onExportClick}>Copy Table</Button>
            <Button onClick={onClose}>OK</Button>
        </DialogActions>
        <Snackbar
            open={snackOpen}
            autoHideDuration={3000}
            onClose={onSnackClose}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            message='Table was copied to clipboard'
        />
        {bookProps
            ? <BookDialog open onCancel={hideBookDialog} 
                practiceId={practiceId} job={job} {...bookProps} />
            : null
        }
    </Dialog>
}

export default ResultDialog;