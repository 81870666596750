import * as React from 'react';
import { createContext, useContext, useMemo, useCallback } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { API } from '../utils/ZiphyAPI';
import { AuthData, User, Token, Account, Role } from '../models/ziphy';
import { API as HedisAPI } from '../utils/Api';
import { API as ZiphyAPI } from '../utils/ZiphyAPI';
import { Client } from '../models/core';
import rg4js from 'raygun4js';
import { analytics } from '../utils/analytics/zipAnalytics'
import {DateTimeStamp} from '../utils/PrevalMacros'

class ZiphyAPIWrapper {
    requestId: number;
    url: string;
    constructor() {
        this.requestId = 0
        this.url = 'http://localhost:5000/myack-rpc/';
    }
    async requestCode(type: 'email' | 'phone', value: string) {
        return await API.requestCode(
            {
                "type": type,
                "value": value,
                "using": "text",
            }
        )
    }

    async login(type: 'email' | 'phone', value: string, code: string) {
        return await API.login(
            {
                "device": 'browser',
                "application": "web",
                "preferable_roles": ["admin", "practice_admin", 'dispatcher'],
                "type": type,
                "value": value,
                "code": code
            }
        );
    }

    async refreshToken(data: AuthData) {
        return await API.refreshToken({
            "session_token": data.sessionToken.value
        });
    }
}


interface AuthValue {
    user: User;
    role?: string | null;
    roles?: Role[] | null;
    account: Account;
    clients?: Client[] | null; // null for admin, [] for no access
    getToken: () => Token | null;
    requestCode: (type: 'email' | 'phone', value: string) => Promise<boolean>;
    login: (type: 'email' | 'phone', value: string, code: string) => Promise<AuthData>;
    logout: () => Promise<void>;
    isActive: () => Promise<boolean>;
}
const AuthContext = createContext<AuthValue | null>(null);


async function roles2clients(roles: Role[]): Promise<Client[] | null> {
    if (roles.find(r => r.role === 'admin')) {
        return null;
    }

    const seenPracticeIds = new Set();
    roles.forEach(r => {
        if ((r.role == 'practice_admin' || r.role == 'dispatcher')) {
            if(r.serviced_practice_ids) {
                r.serviced_practice_ids?.forEach(e => {
                    if(!seenPracticeIds.has(e)) {
                        seenPracticeIds.add(e);
                    }
                })
            } else if(!seenPracticeIds.has(r.practice_id)) {
                seenPracticeIds.add(r.practice_id);
            }
        }
    });

    const practiceArray = Array.from(seenPracticeIds).map((id) => id + '');
    const filter = { practiceId: { $in: practiceArray } }

    const clients = (await HedisAPI.getClients(filter).catch((error) => {
        console.error(`Error Retrieving Clients: ${error}`)
        analytics.error('client_auth_error', {message:error}, true);
    }))?.items || [];
    return clients;
}

interface Props {
    children: string | JSX.Element | JSX.Element[];
}

export const AuthProvider = ({ children }: Props): JSX.Element => {
    // const navigate = useNavigate();
    const [getData, setData] = useLocalStorage<AuthData | null>('auth', null);
    const [getClients, setClients] = useLocalStorage<Client[] | null>('clients', null);
    const api = useMemo(() => (
        new ZiphyAPIWrapper()
    ), []);

    const setRaygunData = useCallback((authData:AuthData|null) => {
        const sessionActive = analytics.isSessionActive();
        if(authData && !sessionActive) {
            const uid = authData.account?.value || 'na';
            const email = (authData.account?.type == 'email' ? authData?.user?.email : '');
            rg4js('setUser', {
                identifier: uid,
                fullName: authData.user?.name || 'na',
                email: email,
                uuid: uid,
                isAnonymous:false
            });
            analytics.startSession(uid, {
                email: email,
                roleId: authData.role?.id || '',
                userId: authData?.user?.id || -1,
                at: authData.account?.type || '',
                dts: DateTimeStamp
            });
        } else if(!authData && sessionActive) {
            rg4js('setUser', {
                identifier: '',
                isAnonymous:true
            });
            analytics.endSession();
        }
    },[]);

    const requestCode = useCallback(async (type: 'email' | 'phone', value: string) => {
        return !!(await api.requestCode(type, value));
    }, [api]);

    const login = useCallback(async (type: 'email' | 'phone', value: string, code: string) => {
        const authData = await api.login(type, value.trim(), code.trim());
        if (authData) {
            HedisAPI.token = authData.accessToken.value;
            ZiphyAPI.token = authData.accessToken.value;
            setClients(await roles2clients(authData.roles || []));
            setData(authData);
            setRaygunData(authData);
            return authData;
        }
        throw Error('No such user');
    }, [api, setClients, setData, setRaygunData]);

    const logout = useCallback(async () => {
        setData(null);
        ZiphyAPI.token = HedisAPI.token = '';
        setRaygunData(null);
    }, [setData, setRaygunData]);

    const refreshTokens = useCallback(async () => {
        const data = getData();
        if (data) {
            if (data.accessToken && new Date(data.accessToken.expiresAt) > new Date()) {
                HedisAPI.token = data.accessToken.value;
                ZiphyAPI.token = data.accessToken.value;
                return true;
            }
            if (data.sessionToken && new Date(data.sessionToken.expiresAt) > new Date()) {
                const authData = await api.refreshToken(data).catch(console.warn);
                if (authData) {
                    HedisAPI.token = authData.accessToken.value;
                    ZiphyAPI.token = authData.accessToken.value;
                    setClients(await roles2clients(authData.roles || []));
                    setData(authData);
                    setRaygunData(authData);
                    return true;
                }
            }
        }
        console.warn('Can`t refresh token');
        ZiphyAPI.token = HedisAPI.token = '';
        setData(null);
        return false;
    }, [api, getData, setClients, setData, setRaygunData]);

    const getToken = useCallback( () => {
        const data = getData() || null;
        return data && data.accessToken;
    }, [getData]);


    const isActive = useCallback(async () => {
        return (await refreshTokens()) || (await logout()) || false;
    }, [refreshTokens, logout]);

    const value = useMemo(() => {
        const data = getData();
        return (
            {
                user: data && data.user || { name: '', email: '', roles: [], id: 0 },
                role: data && data.role && data.role.role,
                roles: data?.roles,
                clients: getClients() || undefined,
                account: data && data.account || { type: 'phone', value: '' },
                getToken,
                requestCode,
                login,
                logout,
                isActive
            }
        );
    }, [getData, getClients, getToken, requestCode, login, logout, isActive]
    );

    return <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>;
}

export const useAuth = (): AuthValue | null => {
    return useContext(AuthContext);
};

export const useToken = () => {
    const auth = useAuth();
    return auth?.getToken() || null;
}