import * as React from 'react';
import { MenuItem } from '@mui/material';

function enumMenuItems(items: (string|[string,string])[], defaultItem:string|undefined = undefined): JSX.Element[] {
    const array = ((defaultItem != undefined && defaultItem != '') ? [(<MenuItem key={'item-d'} value={''}><em>{defaultItem}</em></MenuItem>)] : [])
        .concat(items.map((item, index) => (
            item instanceof Array
                ? <MenuItem key={`item-${index}`} value={item[0]}>{item[1]}</MenuItem>
                : <MenuItem key={`item-${index}`} value={item}>{item}</MenuItem>
        )));
    return array;
}

export default enumMenuItems;