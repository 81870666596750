import * as React from 'react';
import { useState } from 'react';
import { Grid, List, ListItem, ListItemButton, ListItemText, Divider, Typography } from '@mui/material';
import { GridFormItem } from '../../GridForm';
import { PatientRoster } from '../../../models/core';

interface HistoryViewProps {
    rosters?:PatientRoster[];
}

const HistoryView = ({ rosters }: HistoryViewProps): JSX.Element => {

    const [tabIndex, setTabIndex] = useState<number>(0);

    return <Grid container spacing={2}>
        <GridFormItem xs={12}>
            <List>
                {
                    (rosters && rosters.length > 0) ? rosters.map((r) => {
                        return (<ListItem key={r._id} onClick={() => { console.log('')}}>
                            <ListItemButton disableRipple>
                                <ListItemText primary={<b>{r.name}</b> } secondary={ r.importDate }/>
                            </ListItemButton>
                            <Divider/>
                        </ListItem>)
                    })
                    : <Typography component='div' sx={{ display: 'flex', justifyContent: 'center', alignContent:'center' }}>
                        No Rosters Found for Patient
                    </Typography>
                }
            </List>
        </GridFormItem>
    </Grid>
};
export default HistoryView;