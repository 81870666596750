import * as React from 'react';
import { Field } from '../models/core';
import GridForm, { GridFormItem } from './GridForm';
import { AutocompleteField, BoolField, DateField, EnumField, NumberField, TextF } from './fields';

export type CustomValue = string|number|Date|boolean;

interface Props {
    value: { [k: string]: CustomValue };
    fields: Field[];
    itemXs?: number;
    onChange?: (value: { [k: string]: CustomValue|undefined }) => void;
}

const CustomFields = ({ value, fields, itemXs, onChange }: Props): JSX.Element => {
    const callOnChange = React.useCallback((label: string, newValue: CustomValue | null) => {
        onChange && onChange({...value, [label]: newValue || undefined });
    }, [value, onChange]);
    return <GridForm>
        {fields.map((f, i) => (
            <GridFormItem key={`${i}`} xs={itemXs || 6} sx={{ '.MuiFormControl-root': { width: '100%' } }}>{
                f.type == 'boolean' ? <BoolField name={f.label} value={value[f.label] as boolean} onChange={callOnChange} /> :
                f.type == 'date' ? <DateField name={f.label} value={(value[f.label]||null) as Date} 
                                        onChange={callOnChange} autoFillOnClick={true} /> :
                f.type == 'number' ? <NumberField name={f.label} value={value[f.label] as number} onChange={callOnChange} /> :
                f.type == 'enum' ? <EnumField name={f.label} value={(value[f.label] as string) || ''} 
                            clearLabel={f.clearLabel} options={f.items||[]} onChange={callOnChange} /> :
                f.type == 'autocomplete' ? <AutocompleteField name={f.label} value={(value[f.label] as string) || ''} 
                            options={f.items||[]} onChange={callOnChange} /> :
                 <TextF name={f.label} value={value[f.label] as string} onChange={callOnChange} multiline={true}/>                             
            }</GridFormItem>
        ))}
    </GridForm>
}

export default CustomFields;