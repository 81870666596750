import * as React from 'react';
import { useCallback, useState } from 'react';
import { PatientTrackables, TrackableMeasure, TrackableMeasureTypes } from '../../../models/core';
import { Typography, Grid, Box, IconButton } from '@mui/material';

import { GridFormItem } from '../../GridForm';
import { useAuth } from '../../../hooks/useAuth';
import useSnackbar from '../../../hooks/useSnackbar';
import AlertSnack from '../../ErrorSnack'
import { authDocumentInfo } from '../../../utils/ResourceUtils'

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TextTable from '../../TextTable';

export enum MeasureEditorViewSection {
    Demographics = 'Demographics',
    Measures = 'Measures',
    Suspect = 'Suspect',
    Chronic = 'Chronic',
    Diagnosis = 'Diagnosis'
}

export interface MeasureEditorViewState {
    measuresUpdated:boolean;
    measures:TrackableMeasure[];
    suspectUpdated:boolean;
    suspect:TrackableMeasure[];
    chronicUpdated:boolean;
    chronic:TrackableMeasure[];
    diagnosisUpdated:boolean;
    diagnosis:TrackableMeasure[];
}

interface MeasureEditorViewProps {
    title?:string;
    sections?:MeasureEditorViewSection[];
    patientTrackables?:PatientTrackables;
    initialState?:MeasureEditorViewState;
    readonly?:boolean;
    onStateUpdate?:(state:MeasureEditorViewState) => void;
}

export function patientTrackablesToState(patientTrackables?:PatientTrackables, state?:MeasureEditorViewState) : MeasureEditorViewState {
    const conditionsList:Record<string, TrackableMeasure[]> = {};
    if(patientTrackables && patientTrackables.trackable) {
        const trackableList = patientTrackables.trackable;
        trackableList?.forEach((item) => {
            if(!conditionsList[item.type]) {
                conditionsList[item.type] = [];
            }
            conditionsList[item.type].push(item);
        });

        if(state) {
            const mi = state?.measures.filter((i) => !conditionsList[TrackableMeasureTypes.MEASURE]?.find((f) => f.id == i.id));
            const ci = state?.chronic.filter((i) => !conditionsList[TrackableMeasureTypes.CHRONIC]?.find((f) => f.id == i.id));
            const si = state?.suspect.filter((i) => !conditionsList[TrackableMeasureTypes.SUSPECT]?.find((f) => f.id == i.id));
            const di = state?.diagnosis.filter((i) => !conditionsList[TrackableMeasureTypes.DIAGNOSIS]?.find((f) => f.id == i.id));
            
            conditionsList[TrackableMeasureTypes.MEASURE] = [...conditionsList[TrackableMeasureTypes.MEASURE], ...mi];
            conditionsList[TrackableMeasureTypes.CHRONIC] = [...conditionsList[TrackableMeasureTypes.CHRONIC], ...ci];
            conditionsList[TrackableMeasureTypes.SUSPECT] = [...conditionsList[TrackableMeasureTypes.SUSPECT], ...si];
            conditionsList[TrackableMeasureTypes.DIAGNOSIS] = [...conditionsList[TrackableMeasureTypes.DIAGNOSIS], ...di];
        }
    }
    return {
        measuresUpdated:state?.measuresUpdated || false,
        measures:conditionsList[TrackableMeasureTypes.MEASURE] || state?.measures || [],
        chronicUpdated:state?.chronicUpdated || false,
        chronic:conditionsList[TrackableMeasureTypes.CHRONIC] || state?.chronic || [],
        suspectUpdated:state?.suspectUpdated || false,
        suspect:conditionsList[TrackableMeasureTypes.SUSPECT] || state?.suspect || [],
        diagnosisUpdated:state?.diagnosisUpdated || false,
        diagnosis:conditionsList[TrackableMeasureTypes.DIAGNOSIS] || state?.diagnosis || []
    }
}

const MeasureEditorView = ({ title, sections, patientTrackables, readonly=false, initialState, onStateUpdate }: MeasureEditorViewProps): JSX.Element => {

    const activeState = React.useMemo(() => {
        return patientTrackablesToState(patientTrackables, initialState)
    }, [patientTrackables, initialState]);
    
    const { showError: snackError, ...snackProps } = useSnackbar();
    const [measureList, setMeasureList] = useState<TrackableMeasure[]>(activeState.measures || []);
    const [suspectConditionList, setSuspectConditionList] = useState<TrackableMeasure[]>(activeState.suspect || []);
    const [chronicConditionList, setChronicConditionList] = useState<TrackableMeasure[]>(activeState.chronic || []);
    const [diagnosisList, setDiagnosisList] = useState<TrackableMeasure[]>(activeState.diagnosis || []);

    const [measuresUpdated, setMeasuresUpdated] = useState(activeState.measuresUpdated||false);
    const [suspectUpdated, setSuspectUpdated] = useState(activeState.suspectUpdated||false);
    const [chronicUpdated, setChronicUpdated] = useState(activeState.chronicUpdated||false);
    const [diagnosedUpdated, setDiagnosedUpdated] = useState(activeState.diagnosisUpdated||false);
    
    React.useEffect(() => {
        onStateUpdate && onStateUpdate({
            measuresUpdated:measuresUpdated,
            suspectUpdated:suspectUpdated,
            chronicUpdated:chronicUpdated,
            diagnosisUpdated:diagnosedUpdated,
            measures: measureList,
            suspect: suspectConditionList,
            chronic: chronicConditionList,
            diagnosis: diagnosisList,
        });
    },[onStateUpdate, measureList, suspectConditionList, chronicConditionList, diagnosisList,
        measuresUpdated, suspectUpdated, chronicUpdated, diagnosedUpdated]);

    React.useEffect(() => {
        setMeasureList(activeState.measures || []);
        setSuspectConditionList(activeState.suspect || []);
        setChronicConditionList(activeState.chronic || []);
        setDiagnosisList(activeState.diagnosis || []);
        setMeasuresUpdated(activeState.measuresUpdated||false);
        setSuspectUpdated(activeState.suspectUpdated||false);
        setChronicUpdated(activeState.chronicUpdated||false);
        setDiagnosedUpdated(activeState.diagnosisUpdated||false);
    },[activeState]);

    const measureEntryList = React.useMemo(() => [
        {code:'ACP', name:'Advance Care Planning (ACP)' },
        {code:'AWV', name:'Annual Wellness Visit (AWV)' },
        {code:'COA', name:'Care for Older Adults (COA)' },
        {code:'COA-MDR', name:'Care for Older Adults (COA) - Medication Review' },
        {code:'COA-FSA', name:'Care for Older Adults (COA) - Functional Status Assessment' },
        {code:'COA-PA', name:'Care for Older Adults (COA) - Pain Assessment' },
        {code:'CBP', name:'Controlling High Blood Pressure (CBP)' },
        {code:'GSD', name:'Glycemic Status Assessment for Patients with Diabetes (GSD)' },
        {code:'BPD', name:'Blood Pressure Control for Patients With Diabetes (BPD)' },
        {code:'EED', name:'Eye Exam for Patient with Diabetes (EED)' },
        {code:'HBD', name:'Hemoglobin A1c Control for Patients With Diabetes (HBD)' },
        {code:'TRC', name:'Transitions of Care: Medication Reconciliation Post-Discharge (TRC)' },
        {code:'FRM', name:'Fall Risk Management (FRM)' },
        {code:'DSF-E', name:'Depression Screening and Follow-Up for Adolescents and Adults (DSF-E)' },
        {code:'DMS-E', name:'Utilization of the PHQ-9 to Monitor Depression Symptoms for Adolescents and Adults (DMS-E)' },
        {code:'ASF-E', name:'Unhealthy Alcohol Use Screening and Follow-Up (ASF-E)' },
        {code:'MSC', name:'Medical Assistance With Smoking and Tobacco Use Cessation (MSC)' },
        {code:'SPC', name:'Statin Therapy for Patients with Cardiovascular Disease (SPC)' },
        {code:'SPC|SPD', name:'Statin Therapy for Patients With Cardiovascular Disease and Diabetes (SPC, SPD)' },
        {code:'SPD', name:'Statin Therapy for Patients with Diabetes (SPD)' },
        {code:'SUPD', name:'Statin Use in Persons with Diabetes (SUPD)' },
        {code:'KED', name:'Kidney Health Evaluation for Patients With Diabetes (KED)' },
        {code:'FMC', name:'Follow-Up After Emergency Department Visit for People With Multiple High-Risk Chronic Conditions (FMC)' },
        {code:'MUI', name:'Management of Urinary Incontinence in Older Adults (MUI)' },
        {code:'PAO', name:'Physical Activity in Older Adults (PAO)' },
        {code:'MSC', name:'Medical Assistance With Smoking and Tobacco Use Cessation (MSC)' },
        {code:'BCS-E', name:'Breast Cancer Screening (BCS-E)' },
        {code:'COL-E', name:'Colorectal Cancer Screening (COL-E)' },
        {code:'AIS-E', name:'Adult Immunization Status (AIS-E)' },
        {code:'SNS-E', name:'Social Need Screening and Intervention (SNS-E)' },
    ], []);

    const complianceList = React.useMemo(() => [
        {code:'NC', name:'Non-Compliant' },
        {code:'A', name:'Addressed' },
        {code:'C', name:'Compliant' }
    ], []);

    const auth = useAuth();

    const checkRenderSection = useCallback((section:MeasureEditorViewSection, array:MeasureEditorViewSection[]|undefined, def = true) => {
        return (!array || array?.length == 0) ? def : array?.findIndex((s) => section == s) >= 0;
    },[]);

    //  Demographics
    const demographicsComponents = React.useMemo<JSX.Element>(() => {
        return checkRenderSection(MeasureEditorViewSection.Demographics, sections, true) ? (<GridFormItem xs={12}>
            <Grid container spacing={2}>
                <GridFormItem xs={12}>
                    <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>{title || 'Conditions'}</Typography>
                </GridFormItem>   
            </Grid>
            <GridFormItem xs={12}><Box sx={{ height: '0.5em' }} /></GridFormItem>
            </GridFormItem>) : <></>;
    },[sections, checkRenderSection, title]);

    const measureListKeys = React.useMemo(() => {
        const sortedList = measureEntryList.sort((a, b) => a.name.localeCompare(b.name));
        return sortedList?.map((it) => { return { key:it.name, value:it.code } });
    },[measureEntryList]);

    const complianceListKeys = React.useMemo(() => {
        return complianceList?.map((it) => { return { key:it.name, value:it.code } });
    },[complianceList]);

    const copyTableMeasureContent = React.useCallback((contentName:string, content: TrackableMeasure[]) => {
        let copyData:string = contentName;
        content?.forEach((it) => {
            const measureData = measureListKeys?.find((fi) => {return fi.value === it.name;});
            if(measureData) {
                copyData = copyData + `\n${measureData.key}`;
            }
        });
        navigator.clipboard.writeText(copyData);
    },[measureListKeys])

    const copyTableConditionContent = React.useCallback((contentName:string, content: TrackableMeasure[]) => {
        let copyData:string = contentName;
        content?.forEach((it) => { copyData = copyData + `\n${it.name}: ${it.value}`; });
        navigator.clipboard.writeText(copyData);
    },[])

    const checkCanAddRow = React.useCallback((nr: TrackableMeasure, cbk:()=>void) => {
        if(!nr.name || nr.name == '') {
            snackError(new Error('Measure must be set'));
            return [{ key:'measure', label:'Measure must be set' }];
        }
        cbk();
        return undefined;
    }, [snackError]);

    const createMeasureComps = React.useCallback((
        setList:(value:React.SetStateAction<TrackableMeasure[]>) => void,
        setChange:(value:React.SetStateAction<boolean>) => void,
        list:TrackableMeasure[],
    ) => {
        const handleAddRow = (newRow: TrackableMeasure) => {
            setList([...list, {...newRow, 
                id:Date.now().toString(), info:authDocumentInfo(auth?.user, `create: ${newRow.name}`), 
                    type:TrackableMeasureTypes.MEASURE, created:Date.now().toString()}]);
            setChange(true);
        };

        const handleDeleteRow = (newRow: TrackableMeasure) => {
            setList([...list.filter(el => el.id != newRow.id)]);
            setChange(true);
        };

        const handleEditRow = (newRow: TrackableMeasure) => {
            const index = list.findIndex(el => el.id == newRow.id && (el.name !== newRow.name || newRow.value !== el.value));
            if(index !== -1) {
                setList(prevArray => {
                    const newArray = [...prevArray];
                    newArray[index] = {...newRow, 
                        info:authDocumentInfo(auth?.user, `edit: ${list[index].name} to ${newRow.name}`)}
                    return newArray;
                });
                setChange(true);
            }
        };

        return checkRenderSection(MeasureEditorViewSection.Measures, sections, true) ? (
            <Grid container spacing={2}>
            <GridFormItem xs={11}>
                <Typography variant='h6' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>Measures</Typography>
            </GridFormItem>
            <GridFormItem xs={1}>
                <IconButton onClick={() => copyTableMeasureContent('Measures', list)}><ContentCopyIcon/></IconButton>
            </GridFormItem>
            <GridFormItem xs={12}>
                <TextTable
                    readonly={readonly}
                    addRowHeader={"Select Measure:"}
                    rows={list}
                    columns={[
                        { key: 'name', label: 'Measure', entries:measureListKeys, type:'enum' },
                        { key: 'value', label: 'State', entries:complianceListKeys, type:'enum' },
                    ]}
                    onAddRow={handleAddRow}
                    canAddRow={checkCanAddRow}
                    onDeleteRow={handleDeleteRow}
                    onUpdateRow={handleEditRow}
                />
            </GridFormItem>
            <GridFormItem xs={12}><Box sx={{ height: '0.5em' }} /></GridFormItem>
            </Grid>
            ) : <></>;
    },[checkRenderSection, copyTableMeasureContent, checkCanAddRow, sections, measureListKeys, complianceListKeys, auth?.user, readonly]);


    const measureComponents = React.useMemo<JSX.Element>(() => {
        return createMeasureComps(setMeasureList, setMeasuresUpdated, measureList);
    },[measureList, createMeasureComps]);


    const createEditConditionComp = React.useCallback((
        setList:(value:React.SetStateAction<TrackableMeasure[]>) => void,
        setChange:(value:React.SetStateAction<boolean>) => void,
        list:TrackableMeasure[],
        title:string,
        addRowHeader:string,
        section:MeasureEditorViewSection,
        trackableType:TrackableMeasureTypes
    ) => {
        const handleAddRow = (newRow: TrackableMeasure) => {
            setList([...list, {...newRow, id:Date.now().toString(), info:authDocumentInfo(auth?.user, `create ${section}`),
                type:trackableType, created:Date.now().toString() }]);
            setChange(true);
        };

        const handleDeleteRow = (newRow: TrackableMeasure) => {
            setList([...list.filter(el => el.id != newRow.id)]);
            setChange(true);
        };

        const handleEditRow = (newRow: TrackableMeasure) => {
            const index = list.findIndex(el => el.id == newRow.id);
            if(index !== -1) {
                setList(prevArray => {
                    const newArray = [...prevArray];
                    newArray[index] = { ...newRow, info:authDocumentInfo(auth?.user, `edit ${section}`)};
                    return newArray;
                });
                setChange(true);
            }
        };

        return checkRenderSection(section, sections, true) ? (
            <Grid container spacing={2}>
            <GridFormItem xs={11}>
                <Typography variant='h6' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>{title}</Typography>
            </GridFormItem>
            <GridFormItem xs={1}>
                <IconButton onClick={() => copyTableConditionContent(title, list)}><ContentCopyIcon/></IconButton>
            </GridFormItem>
            <GridFormItem xs={12}>
                <TextTable
                    readonly={readonly}
                    addRowHeader={addRowHeader}
                    rows={list}
                    columns={[
                        { key: 'name', label: 'Name' },
                        { key: 'value', label: 'ICD' }
                    ]}
                    onAddRow={handleAddRow}
                    canAddRow={checkCanAddRow}
                    onDeleteRow={handleDeleteRow}
                    onUpdateRow={handleEditRow}
                />
            </GridFormItem>
            <GridFormItem xs={12}><Box sx={{ height: '0.5em' }} /></GridFormItem>
            </Grid>
            ) : <></>;
    },[checkCanAddRow,copyTableConditionContent,sections,checkRenderSection,auth?.user,readonly]);


    const suspectConditionComponents = React.useMemo<JSX.Element>(() => {
        return createEditConditionComp(setSuspectConditionList, setSuspectUpdated, suspectConditionList, 'Suspect Conditions',
            "Enter the suspect condition and ICD code:",MeasureEditorViewSection.Suspect, TrackableMeasureTypes.SUSPECT
        );
    },[suspectConditionList, setSuspectConditionList, createEditConditionComp, setSuspectUpdated]);

    const chronicConditionComponents = React.useMemo<JSX.Element>(() => {
        return createEditConditionComp(setChronicConditionList, setChronicUpdated, chronicConditionList, 'Chronic Conditions',
            "Enter the chronic condition and ICD code:",MeasureEditorViewSection.Chronic, TrackableMeasureTypes.CHRONIC
        );
    },[chronicConditionList, setChronicConditionList, setChronicUpdated, createEditConditionComp]);

    const diagnosisComponents = React.useMemo<JSX.Element>(() => {
        return createEditConditionComp(setDiagnosisList, setDiagnosedUpdated, diagnosisList, 'Diagnosis',
            "Enter the diagnosis and ICD code:",MeasureEditorViewSection.Diagnosis, TrackableMeasureTypes.DIAGNOSIS
        );
    },[diagnosisList, setDiagnosisList, setDiagnosedUpdated, createEditConditionComp]);

    return <Grid container spacing={2}>
        <GridFormItem xs={12}>
            { demographicsComponents } { measureComponents } { suspectConditionComponents }{ chronicConditionComponents }{ diagnosisComponents }
        </GridFormItem>
        <AlertSnack {...snackProps}/>
    </Grid>
}

export default MeasureEditorView;