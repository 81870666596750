import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { ListItemIcon, Avatar, ListItemText } from '@mui/material';
import Person3Icon from '@mui/icons-material/Person3';
import Person4Icon from '@mui/icons-material/Person4';
import { FilterField, FilterValue } from '../FiltersPanel';
import { CheckListItem } from './TransferList';
import { Office } from '../../models/ziphy';
import { API as ZiphyAPI } from '../../utils/ZiphyAPI';
import SelectorTab from './SelectorTab';
import { Message } from './MessagesBox';
import { Agent } from '../../models/core';
import { AutoCloseEnumField } from '../fields';

interface AgentsTabProps {
    practiceId: number;
    messages?: Message[];
    value: Agent[];
    onChange?: (value: Agent[]) => void;
}

function officeAddress(office: Office | null): string {
    return office ? `${office.street} ${office.building}, ${office.city}, ${office.state}, ${office.zip}` : ''
}

function AgentsTab({ practiceId, messages, value, onChange }: AgentsTabProps): JSX.Element {
    const [offices, setOffices] = useState<Office[]>([]);
    const fields = useMemo<FilterField[]>(() => [
        { name: 'name', type: 'string', placeholder: 'Name', width: /*4*/ 7 },
        { name: 'gender', type: 'enum', items: [['male', 'Male'], ['female', 'Female']], emptyLabel: 'Any Gender', width: 3 },
    ], []);
    const defaultValue = useMemo(() => ({
        name: '', gender: ['all'], office: ['all']
    }), []);

    const officesNameList = useMemo<string[]>(() => { return offices?.filter(f => f.name)?.map(v => v.name || '') || [] }, [offices]);
    const [agentOfficeRecord, setAgentOfficeRecord] = useState<Record<string, Office>>({});

    const onChangeCallback = useCallback((values:Agent[]) => {
        const agents = values.map((v) => {
            const office = agentOfficeRecord[v.id] || v.office;
            return { id: v.id, name: v.name, age: v.age, gender: v.gender,
                language: v.language, address: officeAddress(office),
                office: office?.name || ''
        }});
        onChange && onChange(agents);
    },[agentOfficeRecord, onChange])

    const agentOfficeUpdate = useCallback((updated:Agent, newOffice:string) => {
        const office = offices?.find(o => o?.name == newOffice) || null;
        if(!office) {
            return;
        }
        setAgentOfficeRecord((prev) => ({ ...prev, [updated.id]:office }));
    }, [offices])

    const itemFn = useCallback((item: Agent) => (
        <CheckListItem>
            <ListItemIcon>
                <Avatar>
                    {item.gender == 'male' ? <Person4Icon /> : <Person3Icon />}
                </Avatar>
            </ListItemIcon>
            <ListItemText
                primary={item.name}
                secondary={
                    (officesNameList && officesNameList.length > 0) ? <span>
                    <AutoCloseEnumField multiple={false} value={agentOfficeRecord[item.id]?.name || item.office || ''}
                        options={officesNameList} valueToClose='' hiddenLabel
                        onChange={(_l,v) => agentOfficeUpdate(item, v || '')}
                        sx={{ width: "80%", height: "70%", }}
                    />
                    </span> : <></>
                }
            />
        </CheckListItem>
    ), [officesNameList, agentOfficeRecord, agentOfficeUpdate]);

    const onFetch = useCallback(async (filter: FilterValue): Promise<Agent[]> => {

        let workingOffices = offices;
        if(!workingOffices || workingOffices.length == 0) {
            const res = await ZiphyAPI.getOffices({ eq: ['practice_id', practiceId] });
            workingOffices = res?.offices.items;
            setOffices(workingOffices);
        }

        const { expanded: { offices: ofcExp, users }, roles } = await ZiphyAPI.getRoles({
            and: [
                { contains: ['serviced_practice_ids', [practiceId] ]},
                { eq: ['is_active', true] },
                { eq: ['role', 'agent'] },
                /*...((filter['office'] && !filter['office'].includes('all'))
                    ? [{
                        or: (filter['office'] as string[]).map<{ eq: [string, unknown] }>(v => ({
                            eq: ['office_id', offices.find(ofc => ofc.name == v)?.id || 0]
                        }))
                    }] : []),
                ...(!filter['name'].includes('all')
                    ? [{ imatch: ['name', filter['name'] as string] as [string, unknown] }]
                    : [])*/
            ]
        });
        const agentList = roles.items.map<Agent>(role => {
            const office = ofcExp && ofcExp.items.find(({ key }) => key == role.office_id)?.value || null;
            const targetOffice = office ? office : (workingOffices?.length > 0 ? workingOffices[0] : null);
            setAgentOfficeRecord((prev) => ({ ...prev, [role.id+'']:(targetOffice||{} as Office) }));
            return ({
                id: role.id + '',
                name: `${role.first_name} ${role.last_name}`,
                age: 50, // todo: agent age
                gender: users.items.find(({ key }) => key == role.user_id)?.value.gender || 'unknown',
                language: 'english', // todo: agent language
                address: officeAddress(targetOffice),
                office: targetOffice?.name || ''
            });
        }).filter(r => !filter['gender'] || filter['gender'].includes('all') || (filter['gender'] as string[]).includes(r.gender || ''));
        return agentList;
    }, [practiceId, offices]);

    const eqFn = useCallback((x: Agent, y: Agent) => (x.id == y.id), []);

    React.useEffect(() => {
        const fetch = async () => {
            const offices = await ZiphyAPI.getOffices({ eq: ['practice_id', practiceId] });
            setOffices(offices.offices.items);
        };
        fetch().catch(console.error);
    }, [practiceId])

    return <>
        <SelectorTab
            choosenItems={value}
            id='agents'
            {...{ fields, defaultValue, messages, onFetch, itemFn, eqFn, onChange:onChangeCallback }}
        />
    </>
}

export default AgentsTab;