import * as React from 'react';
import { useCallback, useState } from 'react';
import GridForm, { GridFormItem } from '../../GridForm';
import { Autocomplete, Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Client, Patient, Visit, VisitStatus, ParentField, SchedulableSlot,
    AssignmentInfo, CustomFields as CCustomFields, 
    FieldState,
    FieldType} from '../../../models/core';
import { DateTime } from 'luxon';
import { VisitStatuses, MemberInfoUpdates, RefusalReasons, 
    Languages, ScheduledFailedReasons, VisitReasons, UnknownLanguage } from '../../../utils/enums';
import { stringToDateTime } from '../../../utils/ResourceUtils'
import CustomFields, { CustomValue } from '../../CustomFields';
import useAptSearch, { AppointmentExt } from '../../../hooks/useAptSearch';
import { API as ZiphyAPI } from '../../../utils/ZiphyAPI';
import { API } from '../../../utils/Api';
import { AutoCloseEnumField, DateTimeField, EnumField } from '../../fields';
import { analytics } from '../../../utils/analytics/zipAnalytics';
import SchedulableTimeList from '../../SchedulableTimeList';
import { useAuth } from '../../../hooks/useAuth';
import { AutocompleteField as CFAutocompleteField, DateField as CFDateField, EnumField as CFEnumField } from '../../fields';
import debounce from 'lodash.debounce';


interface DateFieldProps {
    name: string;
    label: string;
    value: DateTime | null;
    readOnly?: boolean;
    disabled?: boolean;
    onChange?: (name: string, value: DateTime | null) => void;
}

const DateField = ({ name, label, value, readOnly, disabled, onChange }: DateFieldProps): JSX.Element => {
    const onPickerChange = useCallback((value: string | null) => {
        onChange && onChange(name, value && DateTime.fromISO(value) || null);
    }, [name, onChange]);
    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <Box sx={{ '.MuiFormControl-root': { width: '100%' } }}>
                <DatePicker
                    label={label}
                    inputFormat='yyyy-MM-dd'
                    value={value}
                    renderInput={(params) => <TextField name={name} required {...params} variant='standard' />}
                    onChange={onPickerChange}
                    readOnly={readOnly}
                    disabled={disabled} />
            </Box>
        </LocalizationProvider>
    );
}

interface Props {
    patient: Patient;
    client: Client;
    visit: Visit;
    visitState?:Partial<Visit>;
    readOnly?: boolean;
    onStateChange?:(orig:Visit, changes:Partial<Visit>) => void;
}

const VisitEditorView = ({ visit, client, patient, visitState, readOnly, onStateChange }: Props): JSX.Element => {
    const [activePatient, _setActivePatient] = useState(patient);
    const [activeClient, _setActiveClient] = useState(client);
    const [activeVisit, _setActiveVisit] = useState(visit);

    const [appointment, setAppointment] = useState<AppointmentExt | null>(null);
    const [utrDate, setutrDate] = useState( stringToDateTime(visit?.utrDate) );
    const [scheduled1, setScheduled1] = useState( stringToDateTime(visit?.scheduled1) );
    const [scheduled2, setScheduled2] = useState( stringToDateTime(visit?.scheduled2) );
    const [scheduled3, setScheduled3] = useState( stringToDateTime(visit?.scheduled3) );
    const [scheduled4, setScheduled4] = useState( stringToDateTime(visit?.scheduled4) );
    const [errors, _setSetErrors] = useState<Record<string, boolean>>({});
    const [checkApts, setCheckApts] = useState(false);

    const [schedulableSlots, setSchedulableSlots] = useState<SchedulableSlot|null>(null);
    const [scheduleTime, setScheduleTime] = useState<SchedulableSlot|null>(null);

    const [changeRecord, setChangeRecord] = React.useState<Partial<Visit>>(visitState || {});
    const [changeCustomFields, setChangeCustomFields] = React.useState<CCustomFields>(visitState?.customFields || {});

    const hasField = React.useCallback((key:keyof Visit) => { return changeRecord[key] !== undefined; }, [changeRecord]);
    const getField = React.useCallback((key:keyof Visit) => { return changeRecord[key]; }, [changeRecord]);
    const setField = React.useCallback((value:Partial<Visit>) => { return setChangeRecord((prev) => { return { ...prev, ...value }});}, []);

    const getCurrentValue = React.useCallback((key:keyof Visit, defaultValue:undefined|string|string[]|number|CCustomFields|AssignmentInfo|boolean) => {
        return hasField(key) ? getField(key) : defaultValue;
    },[hasField, getField])

    const auth = useAuth();

    const _onMergeData = React.useCallback(() => {
        const cf = { ...changeRecord, ...{['customFields']:changeCustomFields} };
        onStateChange && onStateChange(visit, cf);
        console.log(cf);
    },[visit, changeRecord, changeCustomFields, onStateChange])

    const debouncedMergeCallback = React.useMemo( () => debounce(_onMergeData, 100), [_onMergeData]);
    React.useEffect(() => {
        debouncedMergeCallback();
        return () => { debouncedMergeCallback.cancel(); };
    }, [debouncedMergeCallback]);


    const checkErrorStatusString = React.useCallback((key:string, required:boolean, value?:string) => {
        if(!required) { return false; }
        const status = !value || value.length == 0;
        return status;
    }, []);

    React.useEffect(() => {
        _setActivePatient(patient);
        _setActiveClient(client);
        _setActiveVisit(visit);
        setChangeRecord(visitState || {});
        setChangeCustomFields(visitState?.customFields || {});

        setScheduled1( stringToDateTime(visit?.scheduled1) );
        setScheduled2( stringToDateTime(visit?.scheduled2) );
        setScheduled3( stringToDateTime(visit?.scheduled3) );
        setScheduled4( stringToDateTime(visit?.scheduled4) );
        setCheckApts(false);
    }, [visit, patient, client, visitState]);

    React.useEffect(() => {
        if(checkApts) {
            const fetch = async () => {
                const [apt] = await Promise.all([
                    (activeVisit?.appointmentId && activeVisit?.appointmentId > 0) && ZiphyAPI.getAppointment(activeVisit.appointmentId + '') || null
                ]);
                apt && setAppointment({ ...apt.appointment, patient: apt.expanded.patients.items[0].value },
                );

                const schedulable = (patient && patient?._id) && await API.getSchedulableSlot(client._id, patient._id).catch(console.log);
                setSchedulableSlots(schedulable||null);
            }
            fetch().catch(console.error)
            setCheckApts(false);
        }
    }, [activeVisit, client, patient, checkApts]);

    const onTextFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setField({[event.target.name]:event.target.value});
    }, [setField]);

    const onEnumFieldChange = useCallback((name: string, value: string | null) => {
        setField({[name]:value||''});
    }, [setField]);

    const onScheduleReasonChange = useCallback((name: string, value: string | null) => {
        setField({[name]:value||''})
    },[setField]);

    const onScheduledDateChange = useCallback((name: string, value: DateTime|null) => {
        setField({[name]:value?.toISO()||''});
        switch (name) {
            case 'scheduled1': setScheduled1(value); break;
            case 'scheduled2': setScheduled2(value); break;
            case 'scheduled3': setScheduled3(value); break;
            case 'scheduled4': setScheduled4(value); break;
            case 'utrDate': setutrDate(value); break;
        }
    },[setField]);
    
    const onCustomFieldsChange = useCallback((value: { [k: string]: string | number | Date | boolean | undefined }) => {
        const et = Object.fromEntries(Object.entries(value).map(([k, v]) => [k, v || '']));
        setChangeCustomFields({...changeCustomFields, ...et});
    }, [changeCustomFields]);

    const combinedCFChanges = React.useMemo(() => {
        const ccp = activeVisit?.customFields || {};
        const cf = changeCustomFields;
        return {...ccp, ...cf} as CCustomFields;
    },[changeCustomFields, activeVisit?.customFields]);

    function mapCustomFields(search:string, genCount:number, source:ParentField[]|undefined) {
        const fieldEmail:ParentField[] = source?.filter(f => !f.field && f.label.startsWith(search)) || [];
        return Object.keys([...new Array(genCount)]).flatMap((_, i) => 
                fieldEmail?.map(f => ({ ...f, label: f.label.slice(search.length + 1).replace('%d', (i + 1).toString()) })));
    }

    const [emailAttemptFields, smsAttemptFields, callAttemptFields, mailAttemptFields, 
        otherCustomFields, disabledFields, allFieldKeys] = React.useMemo(() => {

        const eAR = mapCustomFields('Email:', activeClient?.layout.options.numEmailAttempts || 0, activeClient?.layout.visitFields);
        const eSMS = mapCustomFields('SMS:', activeClient?.layout.options.numSMSAttempts || 0, activeClient?.layout.visitFields);
        const eCall = mapCustomFields('Call:', activeClient?.layout.options.numCallAttempts || 0, activeClient?.layout.visitFields);
        const eMail = mapCustomFields('Mail:', activeClient?.layout.options.numMailAttempts || 0, activeClient?.layout.visitFields);

        const fieldOther = activeClient?.layout.visitFields.filter(f => !f.field
            && (!f.label.startsWith('Call:')
                && !f.label.startsWith('SMS:')
                && !f.label.startsWith('Email:')
                && !f.label.startsWith('Mail:'))) || [];

        const disabled = activeClient?.layout.visitFields.filter(f => f.state == 'disabled').flatMap(f => f.field ? [f.field] : []) || [];

        const allFieldKeys = activeClient?.layout.visitFields.filter(f => !f.field)?.map((f) => f?.label)?.concat(
            eAR.map((f) => f?.label), eSMS.map((f) => f?.label), eCall.map((f) => f?.label), eMail.map((f) => f?.label)
        ) || [];

        return [eAR, eSMS, eCall, eMail, fieldOther, disabled, allFieldKeys];
    }, [activeClient?.layout.visitFields, activeClient?.layout.options.numEmailAttempts, activeClient?.layout.options.numMailAttempts,
        activeClient?.layout.options.numSMSAttempts, activeClient?.layout.options.numCallAttempts]);

    const customCallRendList = React.useMemo(() => {
        const fieldDataSwipe = callAttemptFields.map((f) => {
            if(f.type == 'autocomplete') {
                //f.items = client?.userAccess?.filter(it => it?.assignable)?.map(it => it.name) || client?.repNames || []
                f.items = activeClient?.repNames || []
            }
            return f;
        });

        const workingCF = combinedCFChanges;

        const remapFields = Object.fromEntries((fieldDataSwipe || {}).map(i => [i.label, i]));
        function callOnChange(label: string, newValue: CustomValue | null) {
            onCustomFieldsChange && onCustomFieldsChange({[label]: newValue || undefined });
        }
        const fields = Array.from({ length: (activeClient?.layout?.options.numCallAttempts || 6) }, (_, i) => i + 1);

        return (callAttemptFields.length > 0 &&
            <GridFormItem container xs={12} spacing={2}>
                
                {fields.map((iteration) => (
                    <GridFormItem container key={`kt-${iteration}`} xs={12} spacing={2}>
                    <GridFormItem xs={3}>
                        <CFDateField
                            name={`Call Attempt ${iteration}`}
                            value={(workingCF[`Call Attempt ${iteration}`] || null) as Date}
                            onChange={
                                (label: string, newValue: CustomValue | null) => {
                                    const rep = workingCF[`Call Rep Name ${iteration}`];
                                    if(newValue && (!rep || rep == '')) {
                                        onCustomFieldsChange({
                                            [label]: newValue,
                                            [`Call Rep Name ${iteration}`]:auth?.user.name || ''
                                        });
                                    } else {
                                        callOnChange(label, newValue);
                                    }
                                }
                            }
                            autoFillOnClick={true}
                        />
                    </GridFormItem>
                    <GridFormItem xs={3}>
                        <CFEnumField
                            name={`Phone Type ${iteration}`}
                            value={workingCF[`Phone Type ${iteration}`] as string || ''}
                            clearLabel={remapFields[`Phone Type ${iteration}`]?.clearLabel}
                            options={remapFields[`Phone Type ${iteration}`]?.items || []}
                            onChange={callOnChange}
                        />
                    </GridFormItem>
                    <GridFormItem xs={3}>
                        <CFAutocompleteField
                            name={`Call Rep Name ${iteration}`}
                            value={workingCF[`Call Rep Name ${iteration}`] as string || ''}
                            options={remapFields[`Call Rep Name ${iteration}`]?.items || []}
                            onChange={(l,v) => {(workingCF[`Call Rep Name ${iteration}`] as string || '') != v && callOnChange(l,v) }}
                        />
                    </GridFormItem>
                    <GridFormItem xs={3}>
                        <CFEnumField
                            name={`Outcome of Phone Attempt ${iteration}`}
                            value={workingCF[`Outcome of Phone Attempt ${iteration}`] as string || ''}
                            clearLabel={remapFields[`Outcome of Phone Attempt ${iteration}`]?.clearLabel}
                            options={remapFields[`Outcome of Phone Attempt ${iteration}`]?.items || []}
                            onChange={callOnChange}
                        />
                    </GridFormItem>
                    </GridFormItem>
            ))}
            </GridFormItem>
            || <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>)
    }, [callAttemptFields, onCustomFieldsChange, combinedCFChanges,
        activeClient?.repNames, auth?.user.name, activeClient?.layout?.options.numCallAttempts]);
    
    const create3SegmentOutreachField = React.useCallback((
        fieldDateOfName:string,
        fieldRepName:string,
        fieldOutcomeName:string,
        workingCFChanges:CCustomFields,
        targetdFields:{ label: string; forChild: boolean; id: string; state: FieldState; defaultLabel?: string; clearLabel?: string; section?: string;
            field?: string; type?: FieldType; items?: string[]; minItems?: 0; }[],
        repNameList:string[],
        onFieldsChange:(value: { [k: string]: string | number | Date | boolean | undefined; }) => void
    ) => {
        const fieldDataSwipe = targetdFields.map((f) => {
            if(f.type == 'autocomplete') {
                f.items = repNameList || [];
            }
            return f;
        });

        function callOnChange(label: string, newValue: CustomValue | null) {
            onFieldsChange && onFieldsChange({[label]: newValue || undefined });
        }

        const workingCF = workingCFChanges;

        const remapFields = Object.fromEntries((fieldDataSwipe || {}).map(i => [i.label, i]));
        const dateOfName = fieldDateOfName;
        const repName = fieldRepName;
        const outcomeName = fieldOutcomeName;

        const rapNameValue = workingCF[repName] as string || '';

        return (targetdFields.length > 0 &&
            <GridFormItem container xs={12} spacing={2}>
                <GridFormItem xs={4}>
                    <CFDateField
                        name={dateOfName}
                        value={(workingCF[dateOfName] || null) as Date}
                        onChange={
                            (label: string, newValue: CustomValue | null) => {
                                const rep = workingCF[repName];
                                if(newValue && (!rep || rep == '')) {
                                    onFieldsChange({
                                        [label]: newValue || undefined,
                                        [repName]:auth?.user.name || ''
                                    });
                                } else {
                                    callOnChange(label, newValue);
                                }
                            }
                        }
                        autoFillOnClick={true}
                    />
                </GridFormItem>
                <GridFormItem xs={4}>
                    <CFAutocompleteField name={repName} value={rapNameValue} options={remapFields[repName]?.items || []}
                        onChange={(l,v) => {rapNameValue != v && callOnChange(l,v) }}/>
                </GridFormItem>
                <GridFormItem xs={4}>
                    <CFEnumField name={outcomeName} value={workingCF[outcomeName] as string || ''}
                        clearLabel={remapFields[outcomeName]?.clearLabel} options={remapFields[outcomeName]?.items || []}
                        onChange={callOnChange}/>
                </GridFormItem>
            </GridFormItem>
        || <></>);
    },[auth?.user.name])

    const customMailRendList = React.useMemo<JSX.Element>(() => {
        return create3SegmentOutreachField('Date of Mail Attempt','Mail Rep Name','Outcome of Mail Attempt', 
            combinedCFChanges, mailAttemptFields, activeClient?.repNames || [], onCustomFieldsChange);
    },[create3SegmentOutreachField, mailAttemptFields, combinedCFChanges, activeClient?.repNames, onCustomFieldsChange])

    const customEmailRendList = React.useMemo<JSX.Element>(() => {
        return create3SegmentOutreachField('Date of Email Attempt','Email Rep Name','Outcome of Email Attempt', 
            combinedCFChanges, emailAttemptFields, activeClient?.repNames || [], onCustomFieldsChange);
    },[create3SegmentOutreachField, emailAttemptFields, combinedCFChanges, activeClient?.repNames, onCustomFieldsChange])

    const customSMSRendList = React.useMemo<JSX.Element>(() => {
        return create3SegmentOutreachField('Date of Text Attempt','Text Rep Name','Outcome of Text Attempt', 
            combinedCFChanges, smsAttemptFields, activeClient?.repNames || [], onCustomFieldsChange);
    },[create3SegmentOutreachField, smsAttemptFields, combinedCFChanges, activeClient?.repNames, onCustomFieldsChange])


    const customGenFieldsRendList = React.useMemo<JSX.Element>(() => {
        if(otherCustomFields.length == 0) {
            return <></>;
        }

        const workingCF = combinedCFChanges
        return  <GridFormItem xs={12}>
                <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                    Custom Fields
                </Typography>
                <Box sx={{ height: '2em', display: 'block' }} />
                <CustomFields value={workingCF || {}} fields={otherCustomFields} onChange={onCustomFieldsChange} />
            </GridFormItem>
    }, [otherCustomFields, onCustomFieldsChange, combinedCFChanges]);


    const readOnlyFieldsRendList = React.useMemo<JSX.Element>(() => {
        if(allFieldKeys.length == 0) { return <></>; }

        const workingCF = combinedCFChanges;
        const keysWithoutFields = Object.keys(workingCF).filter((k) => !allFieldKeys.includes(k)).map((key, i) => (
            <GridFormItem key={`${i}`} xs={4}><TextField label={key} name={key} value={workingCF[key] as string} variant='standard'/></GridFormItem>
        )) || [];

        return (keysWithoutFields.length > 0 && (<GridFormItem xs={12}><GridForm>{ keysWithoutFields }</GridForm></GridFormItem>)|| <></>);
    }, [allFieldKeys, combinedCFChanges]);

    const schedulablePatientTimeComp = React.useMemo<JSX.Element>(() => {
        const openBookDialog = (slot:SchedulableSlot|null|undefined) => {
            setScheduleTime(slot||null);
        };

        return (
            <GridFormItem xs={12}>
                <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                    Proposed Schedule
                </Typography>
                As Of: { (schedulableSlots
                    ? DateTime.fromISO(schedulableSlots?.info?.lastEditedDate || '').toLocaleString(DateTime.DATE_SHORT) 
                    : DateTime.now().toLocaleString(DateTime.DATE_SHORT)) || '' }
                <SchedulableTimeList items={schedulableSlots
                    && DateTime.fromISO(schedulableSlots?.time || '').toUnixInteger() > DateTime.now().toUnixInteger()
                    ?[schedulableSlots]:undefined} onBook={openBookDialog}/>
            </GridFormItem>
        );
    },[schedulableSlots]);


    const { apt, apts, onAptChange, onAptInputChange, aptLabel, compareApts } = useAptSearch(
        checkApts, activeClient, activePatient, scheduled1 || null, appointment
    )

    const [callAttempts, emailAttempts, smsAttempts, mailAttempts, contactAttempts] = React.useMemo(() => {
        const workingCF = combinedCFChanges;

        const callA = callAttemptFields.filter((f) => { return (f?.type == 'date') && workingCF[f.label]; } )?.length || 0;
        const emailA = (emailAttemptFields.filter((f) => { return (f?.type == 'date') && workingCF[f.label]; } )?.length || 0);
        const smsA = (smsAttemptFields.filter((f) => { return (f?.type == 'date') && workingCF[f.label]; } )?.length || 0);
        const mailA = (mailAttemptFields.filter((f) => { return (f?.type == 'date') && workingCF[f.label]; } )?.length || 0)

        const total = callA + emailA + smsA + mailA;
        return [callA, emailA, smsA, mailA, total];
    }, [callAttemptFields, emailAttemptFields, smsAttemptFields, mailAttemptFields, combinedCFChanges]);

    // TODO: HACK
    React.useEffect(() => {
        const changes:Record<string ,number> = {};
        if(emailAttempts != activeVisit.numEmails) { changes['numEmails'] = emailAttempts; }
        if(callAttempts != activeVisit.numCalls) { changes['numCalls'] = callAttempts; }
        if(smsAttempts != activeVisit.numSMS) { changes['numSMS'] = smsAttempts; }
        if(mailAttempts != activeVisit.numMails) { changes['numMails'] = mailAttempts; }
        if(contactAttempts != activeVisit.numContacts) { changes['numContacts'] = contactAttempts; }
        if(Object.keys(changes).length != 0) {
            setChangeRecord((prev) => ({...prev, ...changes}));
        }
    }, [activeVisit.numCalls, activeVisit.numEmails, activeVisit.numSMS, activeVisit.numMails, emailAttempts,
        callAttempts, smsAttempts, mailAttempts, contactAttempts, activeVisit.numContacts]);

    const status = getCurrentValue('status',activeVisit.status) as string||VisitStatus.NEW.toString();
    return (
        <GridFormItem xs={12} marginRight={'8px'}>
            <GridForm>
            <GridFormItem xs={12} >
                <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                    Core
                </Typography>
            </GridFormItem>
            <GridFormItem xs={4}>
                <EnumField name='status' label='Status' value={status}
                    error={checkErrorStatusString('status', true, status)} onChange={onEnumFieldChange}
                    options={VisitStatuses} required readOnly={readOnly} />
            </GridFormItem>

            <GridFormItem xs={4}>
                <AutoCloseEnumField name='visitReasons' multiple={true} value={
                    getCurrentValue('visitReasons', activeVisit.visitReasons) as string[] || []}
                    options={VisitReasons}
                    label='Visit Reason'
                    onChange={(_: string, newValue: string[] | null) => {
                        setField({visitReasons:newValue || []})
                    }}
                    disabled={readOnly || disabledFields.includes('visitReasons')}
                />
            </GridFormItem>

            <GridFormItem xs={4}>
                <Autocomplete
                    options={apts}
                    value={apt}
                    onChange={onAptChange}
                    onInputChange={onAptInputChange}
                    autoHighlight
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            name='appointment'
                            label='Patient name, apt date or id'
                            variant='standard'
                            fullWidth
                        />
                    }
                    getOptionLabel={aptLabel}
                    isOptionEqualToValue={compareApts}
                    readOnly={readOnly}
                />
            </GridFormItem>

            <GridFormItem xs={6}>
                <EnumField name='refusalReason' label='Refusal or UTR Reason' error={errors.refusalReason}
                    value={getCurrentValue('refusalReason', activeVisit.refusalReason) as string||''}
                    onChange={onEnumFieldChange} readOnly={readOnly} options={RefusalReasons} clearLabel={'Clear Field'}/>
            </GridFormItem>
            <GridFormItem xs={6}>
                <DateField name='utrDate' value={ utrDate }
                    label='Date Referred for Unable to Contact'
                    onChange={onScheduledDateChange} readOnly={readOnly}/>
            </GridFormItem>
            <EnumField name='language' label='Language' value={ getCurrentValue('language', activeVisit.language) as string || UnknownLanguage}
                onChange={onEnumFieldChange} readOnly={readOnly} options={Languages} error={errors.language}/>
            <EnumField name='memberInfoUpdate' value={getCurrentValue('memberInfoUpdate', activeVisit.memberInfoUpdate) as string||''}
                label='Updated information for member' error={errors.language}
                onChange={onEnumFieldChange} options={MemberInfoUpdates} readOnly={readOnly} clearLabel={'Clear Field'}/>

            <TextField name='numReschedules' value={getCurrentValue('numReschedules',activeVisit.numReschedules) || 0}
                label='Number of Reschedules to Date' error={errors.numReschedules}
                onChange={
                    (e) => { setField({'numReschedules':(!e.target.value.startsWith('-') ? Number.parseInt(e.target.value) : 0)}) }
                } variant='standard' type='number' disabled={readOnly} fullWidth />
            <GridFormItem xs={12}>
                <TextField name='comments' label='ZiphyCare Comments' value={getCurrentValue('comments', activeVisit.comments) as string || ''}
                    onChange={onTextFieldChange} variant='standard' multiline fullWidth />
            </GridFormItem>

            <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>

            <GridFormItem xs={12} >
                <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                    Client
                </Typography>
            </GridFormItem>

            <GridFormItem xs={12} >
                <TextField name='clientDataEntry' value={getCurrentValue('clientDataEntry', activeVisit.clientDataEntry) || ''}
                    label='Client Data Entry' error={errors.clientDataEntry}
                    onChange={onTextFieldChange} variant='standard'
                    disabled={readOnly || disabledFields.includes('clientDataEntry')} fullWidth />
            </GridFormItem>
            <GridFormItem xs={12} >
                <TextField name='clientNotes' value={getCurrentValue('clientNotes', activeVisit.clientNotes) || ''}
                    label='Client Notes' error={errors.clientNotes}
                    onChange={onTextFieldChange} variant='standard'
                    disabled={readOnly || disabledFields.includes('clientNotes')} fullWidth />
            </GridFormItem>

            <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>

            <GridFormItem xs={12} >
                <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                    Outreach History
                </Typography>
            </GridFormItem>

            <GridFormItem xs={6}>
                <Typography>{`Contact Attempts: ${contactAttempts}`}</Typography>
            </GridFormItem>
            <GridFormItem xs={6}>
                <Typography>{`Call Attempts: ${callAttempts}`}</Typography>
            </GridFormItem>

            {
                /* Call Attempts */
                customCallRendList
            }
            <GridFormItem xs={4}><Box/></GridFormItem>
            <GridFormItem xs={12}><Box/></GridFormItem>
            {
                /* Email Render List */
                customEmailRendList
            }
            {
                /* SMS Render List */
                customSMSRendList
            }
            {
                customMailRendList
            }
            <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>

            {
                /* Proposed Schedule */
                schedulablePatientTimeComp
            }
            <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>

            {/* Appointment History*/}
            <GridFormItem xs={12}>
                <Typography variant='h5' component='div' sx={{ display: 'flex', justifyContent: 'start' }}>
                    Appointment History
                </Typography>
            </GridFormItem>

            <GridFormItem xs={6}>
                <DateTimeField name='scheduled1' value={ scheduled1 }
                    label='Scheduled 1st'
                    onChange={onScheduledDateChange} readOnly={readOnly}
                    disabled={disabledFields.includes('scheduled1')} />
            </GridFormItem>
            <GridFormItem xs={6}>
                <EnumField name='scheduledReason1' value={ getCurrentValue('scheduledReason1', '') as string || '' }
                    label='Reason Appointment Did Not Happen' error={errors.scheduledReason1}
                    onChange={onScheduleReasonChange} options={ScheduledFailedReasons} readOnly={readOnly} clearLabel={'Clear Field'}
                    disabled={disabledFields.includes('scheduledReason1')} />
            </GridFormItem>

            <GridFormItem xs={6}>
                <DateTimeField name='scheduled2' value={ scheduled2 }
                    label='Scheduled 2nd'
                    onChange={onScheduledDateChange} readOnly={readOnly}
                    disabled={disabledFields.includes('scheduled2')} />
            </GridFormItem>
            <GridFormItem xs={6}>
                <EnumField name='scheduledReason2' value={ getCurrentValue('scheduledReason2', '') as string || ''}
                    label='Reason Appointment Did Not Happen' error={errors.scheduledReason2}
                    onChange={onScheduleReasonChange} options={ScheduledFailedReasons} readOnly={readOnly} clearLabel={'Clear Field'}
                    disabled={disabledFields.includes('scheduledReason2')} />
            </GridFormItem>

            <GridFormItem xs={6}>
                <DateTimeField name='scheduled3' value={ scheduled3 }
                    label='Scheduled 3rd'
                    onChange={onScheduledDateChange} readOnly={readOnly}
                    disabled={disabledFields.includes('scheduled3')} />
            </GridFormItem>
            <GridFormItem xs={6}>
                <EnumField name='scheduledReason3' value={ getCurrentValue('scheduledReason3', '') as string || ''}
                    label='Reason Appointment Did Not Happen' error={errors.scheduledReason3}
                    onChange={onScheduleReasonChange} options={ScheduledFailedReasons} readOnly={readOnly} clearLabel={'Clear Field'}
                    disabled={disabledFields.includes('scheduledReason3')} />
            </GridFormItem>

            <GridFormItem xs={6}>
                <DateTimeField name='scheduled4' value={ scheduled4 }
                    label='Scheduled 4th'
                    onChange={onScheduledDateChange} readOnly={readOnly}
                    disabled={disabledFields.includes('scheduled4')} />
            </GridFormItem>
            <GridFormItem xs={6}>
                <EnumField name='scheduledReason4' value={getCurrentValue('scheduledReason4', '') as string || ''}
                    label='Reason Appointment Did Not Happen' error={errors.scheduledReason4}
                    onChange={onEnumFieldChange} options={ScheduledFailedReasons} readOnly={readOnly} clearLabel={'Clear Field'}
                    disabled={disabledFields.includes('scheduledReason4')} />
            </GridFormItem>

            <GridFormItem xs={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
                <FormControlLabel sx={{ '& span.MuiButtonBase-root': { paddingBottom: 0, paddingTop: 0 } }}
                    control={
                        <Checkbox
                            name='sentToPCP'
                            readOnly={readOnly}
                            checked={ getCurrentValue('sentToPCP', activeVisit.sentToPCP || false) as boolean || false }
                            onChange={(e)=>{setField({sentToPCP:e.target.checked})}} />
                    }
                    disabled={disabledFields.includes('sentToPCP')}
                    label="Sent to PCP" />
            </GridFormItem>
            <GridFormItem xs={6}><Box sx={{ height: '2em' }} /></GridFormItem>
            <GridFormItem xs={12}><Box sx={{ height: '2em' }} /></GridFormItem>

            <GridFormItem xs={12}>
                <TextField name='notes' value={getCurrentValue('notes', activeVisit.notes)}
                    label='Notes' error={errors.notes}
                    onChange={onTextFieldChange} variant='standard'
                    multiline fullWidth disabled={readOnly} />
            </GridFormItem>
            <Box sx={{ height: '2em', display: 'block' }} />
            {
                /* Other custom fields */
                customGenFieldsRendList
            }
            {
                readOnlyFieldsRendList
            }
            <input type='hidden' name='appointmentId' value={apt && apt.id || undefined} />

            </GridForm>
        </GridFormItem>
    )
}

export default VisitEditorView;