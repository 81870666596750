import * as React from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import './index.css';
import ClientsPage, { loader as clientsLoader, loader } from './routes/clients';
import PatientsPage, { loader as patientsLoader } from './routes/patients';
import PatientsClustersPage, { loader as patientsClustersLoader } from './routes/patientClusters';

import MeasuresPage, { loader as measuresLoader } from './routes/measures';
import CampaignsPage, { loader as campaignsLoader } from './routes/campaigns'

import EnterCodeFragment from './components/auth/EnterCodeFragment';
import RequestCodeFragment from './components/auth/RequestCodeFragment';
import LoginPage from './routes/login';

import App from './App';
import ErrorPage from './components/ErrorPage';
import WrongRoleFragment from './components/auth/WrongRole';
import VisitsPage, { loader as visitsLoader } from './routes/visits';
import PatientsLivePage, { loader as patientsLiveLoader } from './routes/patientLive';

const router = createBrowserRouter([
    {
        path: '/auth',
        element: <LoginPage />,
        children: [
            {
                path: 'login',
                element: <RequestCodeFragment />
            },
            {
                path: 'code',
                element: <EnterCodeFragment />
            },
            {
                path: 'error',
                element: <WrongRoleFragment />
            }
        ]
    },
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Navigate to='clients' />
            },
            {
                path: 'clients',
                element: <ClientsPage />,
                loader: clientsLoader,
                children: [
                    {
                        path: ':clientId',
                        children: [
                            {
                                index: true,
                                element: <Navigate to='patients' />
                            },
                        ]
                    },
                    {
                        path: ':clientId',
                        children: [
                            {
                                index: true,
                                element: <Navigate to='campaigns' />
                            },
                        ]
                    }
                ]
            },
            {
                path: 'clients/:clientId/patients',
                element: <PatientsPage />,
                loader: patientsLoader,
                children: [
                    {
                        path: ":patientId",
                        children: [
                            {
                                index: true,
                                element: <Navigate to='visits' />
                            },
                        ]
                    },
                ]
            },
            {
                path: 'clients/:clientId/patientsClusters',
                element: <PatientsClustersPage />,
                loader: patientsClustersLoader,
                children: [
                    {
                        path: ":patientId",
                        children: [
                            {
                                index: true,
                                element: <Navigate to='visits' />
                            },
                        ]
                    },
                ]
            },
            {
                path: 'clients/:clientId/patientsLive',
                element: <PatientsLivePage />,
                loader: patientsLiveLoader,
                children: [
                    {
                        path: ":patientId",
                        children: [
                            {
                                index: true,
                                element: <Navigate to='visits' />
                            },
                        ]
                    },
                ]
            },
            {
                path: 'clients/:clientId/campaigns',
                element: <CampaignsPage />,
                loader: campaignsLoader,
                children: [
                ]
            },
            {
                path: 'clients/:clientId/patients/:patientId/visits',
                element: <VisitsPage />,
                loader: visitsLoader,
                children: [
                    {
                        path: ":visitId",
                        children: [
                            {
                                index: true,
                                element: <Navigate to='measures' />
                            },
                        ]
                    },
                ]
            },
            {
                path: 'clients/:clientId/patientsClusters/:patientId/visits',
                element: <VisitsPage />,
                loader: visitsLoader,
                children: [
                    {
                        path: ":visitId",
                        children: [
                            {
                                index: true,
                                element: <Navigate to='measures' />
                            },
                        ]
                    },
                ]
            },
            {
                path: 'clients/:clientId/patients/:patientId/visits/:visitId/measures',
                element: <MeasuresPage />,
                loader: measuresLoader,
            }
        ]
    },

]);

const RoutesRoot = (): JSX.Element => {
    return <RouterProvider router={router} />
}
export default RoutesRoot;