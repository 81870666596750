import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, PaperProps } from '@mui/material';


export interface DialogButton {
    text?:string;
    color?:"inherit" | "error" | "primary" | "secondary" | "info" | "success" | "warning"|undefined
    type?:"button" | "submit" | "reset" | undefined;
    onClick?:()=>void;
}
interface Props {
    open: boolean;
    title?:string|JSX.Element|JSX.Element[];
    body?:string|JSX.Element|JSX.Element[];
    buttons?:DialogButton[];
    backdropClose?:boolean;
    paperProps?:PaperProps|undefined;
    onClose?:()=>void;
}

const SimpleDialog = ({ open, title, body, buttons, backdropClose, paperProps, onClose }: Props): JSX.Element => {
    const handleDialogClose = React.useCallback(async (event:object, reason: "backdropClick" | "escapeKeyDown") => {
        if (!backdropClose && (reason && reason === "backdropClick")) 
            return;
        onClose && await onClose();
    },[onClose, backdropClose]);

    const buttonElements = React.useMemo(() => {
        return buttons?.map((bt, i) => {
            return <Button key={i} type={bt.type} onClick={bt.onClick} color={bt.color }>{bt.text}</Button>
        })
    },[buttons]);


    return <Dialog open={open} onClose={handleDialogClose} PaperProps={paperProps}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            {body}
        </DialogContent>
        <DialogActions>
            { buttonElements }
        </DialogActions>
    </Dialog>
}

export default SimpleDialog;
